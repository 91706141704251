.agents-list::after {
  position: absolute;
  left: 3.75rem;
  top: 2rem;
  z-index: -10;
  height: calc(100% - 4rem);
  border-right-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(152 221 225 / var(--tw-border-opacity));
  --tw-content: '';
  content: var(--tw-content)
}

.table-segmented {
  -webkit-user-select: none;
          user-select: none;
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(248 245 253 / var(--tw-bg-opacity))
}

.table-segmented .rc-segmented-item {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

/* .table-segmented:not(.rc-segmented-disabled) .rc-segmented-item { */

.table-segmented.rc-segmented-disabled .rc-segmented-item:hover,
.table-segmented .rc-segmented-item {
  --tw-text-opacity: 1;
  color: rgb(0 145 174 / var(--tw-text-opacity))
}

.table-segmented .rc-segmented-item-label,
.table-segmented .rc-segmented-item-selected .rc-segmented-item-label {
  font-weight: 400
}

.table-segmented .rc-segmented-item-input:checked + .rc-segmented-item-label {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.table-segmented:not(.rc-segmented-disabled) .rc-segmented-item-selected,
.table-segmented:not(.rc-segmented-disabled) .rc-segmented-thumb {
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  background: #49c2cb
}

.table-segmented .bg-disabled.rc-segmented-item-selected {
  background-color: rgb(73 194 203 / 0.5)
}

.table-segmented .rc-segmented-disabled {
  opacity: 0.2
}
