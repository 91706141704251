.max-w-320 {
  max-width: 320px;
}

.w-320 {
  width: 320px;
}

.max-w-420 {
  max-width: 420px;
}

.w-420 {
  width: 420px;
}

.max-w-520 {
  max-width: 520px;
}

.w-520 {
  width: 520px;
}

@media (min-width: 1024px) {
  .lg\:w-520 {
    width: 520px;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:max-w-520 {
    max-width: 520px;
  }
}

.border-teal-300 {
  border-color: #e6f7f8;
}

/* table */

.table-responsive {
  max-height: 500px;
  overflow: auto;
  scrollbar-color: #49c2cb #fefefe;
  scrollbar-width: thin;
  padding-right: 8px;
}

.table-responsive::-webkit-scrollbar {
  width: 6px;
  /* width of the entire scrollbar */
}

.table-responsive::-webkit-scrollbar-track {
  background: #fefefe;
  /* color of the tracking area */
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #49c2cb;
  /* color of the scroll thumb */
  border-radius: 8px;
  /* roundness of the scroll thumb */
}

.table {
  width: 100%;
  border-collapse: inherit;
  border-spacing: 0 8px;
}

.table thead th {
  position: sticky;
  top: 0;
  height: 2.75rem;
}

.table td,
.table th {
  white-space: nowrap;
  padding: 0.75rem 0.75rem;
}

.table tbody tr {
  border-spacing: 1rem;
  cursor: pointer;
}

.table tbody tr td {
  overflow: hidden;
}

.table tbody tr td:last-child,
.table thead tr th:last-child {
  border-radius: 0 5px 5px 0;
}

.table tbody tr td:first-child,
.table thead tr th:first-child {
  border-radius: 5px 0 0 5px;
}

.table tbody tr:nth-child(2n) td,
.table th {
  background-color: #E6F7F8;
}

.table tbody tr.selected td {
  /* border-width: 1px 0 1px 0; */
  /* border-color: #70cfd6; */
  border-style: none;
}

.table tbody tr.selected td:first-child {
  /* border-width: 1px 0 1px 1px; */
}

.table tbody tr.selected td:last-child {
  /* border-width: 1px 1px 1px 0; */
}

/* data table */

.reason-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
  display: inline-block;
}

@media (min-width: 1024px) {
  .reason-title {
    max-width: 100%;
  }
}

/* bubble chart */

.trends-title {
  font-size: 20px;
  line-height: 23.44px;
  color: #425b76;
  font-weight: 400;
}

.current-channel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  position: absolute;
  background: #f2eafa;
  border-radius: 5px;
  z-index: 2;
  left: 1rem;
  top: 1rem;
  color: #33475b;
}

.status-color + span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #425b76;
}

.status-color {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.status-color.status-color__increase {
  background: linear-gradient(91.89deg, #32a6ae -6.8%, #00bba7 100.34%);
}

.status-color.status-color__decrease {
  background: linear-gradient(91.89deg, #f2547d -6.8%, #ff7959 100.34%);
}

.change-channel-icon {
  color: #32a6ae;
}

.change-channel-btn {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e6f7f8;
  padding: 0.5rem;
  cursor: pointer;
}

.change-channel-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.change-channel-btn__prev {
  border-radius: 20px 2px 2px 20px;
}

.change-channel-btn__next {
  border-radius: 2px 20px 20px 2px;
}
