@tailwind base;
@tailwind components;
@tailwind utilities;

.text-primary {
  color: #474e55;
}
.text-secandary {
  color: #425b76;
}
.text-reducing {
  color: #f2547d;
}
.text-anhancer {
  color: #007980;
}
.text-disabled {
  color: #7b93af;
}
.text-link {
  color: #0091ae;
}
.text-Orange {
  color: #cc5900;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), local("Roboto-Regular"),
    url(../assets/fonts/Roboto/Regular/Roboto-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url(../assets/fonts/Roboto/Bold/Roboto-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto Light"), local("Roboto-Light"),
    url(../assets/fonts/Roboto/Light/Roboto-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url(../assets/fonts/Roboto/Medium/Roboto-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

body {
  font-family: "Roboto";
  color: #474e55;
}

.page-title {
  @apply flex items-center;
}

.sidebar-wrapper {
  @apply fixed bottom-0 left-0 top-0 flex w-[64px] flex-col overflow-hidden transition-all lg:w-[256px];
  @apply before:absolute before:left-[-10%] before:top-[-6%] before:-z-10 before:hidden before:h-[167px] before:w-[188px] before:content-[''] lg:before:inline-block;
  @apply before:hidden after:absolute after:bottom-[0] after:left-[-60%] after:-z-10 after:h-[291px] after:w-[291px] after:content-[''] lg:before:inline-block;
}

.sidebar-wrapper.sidebar-wrapper--closed {
  @apply lg:w-[64px];
}

.sidebar-wrapper::before {
  background-image: url(../assets/images/vectors/sidebar-top-bubbles.svg);
}

.sidebar-wrapper::after {
  background-image: url(../assets/images/vectors/sidebar-bottom-bubble.svg);
}

.sidebar-header {
  @apply before:absolute before:-right-0 before:-top-4 before:z-[1] before:hidden before:h-[75px] before:w-[37.5px] before:rotate-[-10deg] before:bg-[url(../assets/images/vectors/sidebar-semicircle.svg)] before:bg-no-repeat before:content-[""] lg:before:inline-block;
}

/* segmented */

.rc-segmented {
  border: 1px solid #c0eaed;
  border-radius: 50vh;
  font-size: 0.75rem;
  padding: 4px 4px;
  background: transparent;
}

/* .rc-segmented-group> :not([hidden])~ :not([hidden]) {
  margin-left: 8px;
  margin-right: 0;
} */

.overflow-visiable > div,
.overflow-visiable .highcharts-container {
  overflow: visible !important;
}
.highcharts-tooltip > span {
  @apply !bg-transparent !p-0;
}

.rc-segmented-item-input {
  margin: 0;
  display: none;
}

.rc-segmented-item {
  display: flex;
  align-items: center;
  border-radius: 50vh;
  padding: 4px 8px;
  min-height: auto;
  white-space: nowrap;
  color: #7b93af;
}

.rc-segmented-item-label {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

.label-semibold .rc-segmented-item-label {
  font-weight: 600;
}

.rc-segmented-item-selected .rc-segmented-item-label {
  font-weight: 600;
}

.rc-segmented-item-selected.rc-segmented-item:hover,
.rc-segmented-item-selected.rc-segmented-item:focus {
  color: #fff;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  margin-left: 12px;
  margin-right: 0;
}

.rc-segmented-item:hover,
.rc-segmented-item:focus {
  color: #7b93af;
}

.rc-segmented-item-selected,
.rc-segmented-thumb {
  background: #32a6ae;
  color: #fff;
  height: 100%;
}

.rc-segmented-thumb {
  border-radius: 50vh;
  height: 100%;
  bottom: 0;
}

/* Dialog */

.rc-dialog-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #373737;
  background-color: rgba(55, 55, 55, 0.6);
  height: 100%;
  filter: alpha(opacity=50);
  z-index: 1050;
}

.rc-dialog-wrap {
  @apply fixed bottom-0 left-0 right-0 top-0 z-[1050] overflow-hidden overflow-y-auto;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.rc-dialog {
  @apply mx-auto block h-auto max-h-screen w-full max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl;
}

.rc-dialog-content {
  @apply relative my-12 block rounded-lg border bg-white shadow-lg;
  background-clip: padding-box;
  outline: 0;
}

.rc-dialog-close {
  @apply absolute z-10 cursor-pointer border-0 bg-transparent font-bold text-black;
  font-size: 21px;
  right: 20px;
  top: 12px;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
  text-decoration: none;
}

.rc-dialog-close-x::after {
  content: "×";
}

.rc-dialog-header {
  padding: 13px 20px 14px;
  border-radius: 5px 5px 0 0;
  background: #fff;
  color: #666;
  border-bottom: 1px solid #e9e9e9;
}

.rc-dialog-body {
  position: relative;
  padding: 15px;
}

/* Calendar */

.Calendar__day {
  @apply text-[#33475B];
}

.Calendar {
  @apply pt-0 text-[#33475B] shadow-none;
}

.Calendar__header {
  @apply mb-2 border-b border-cyan-400 px-4 py-3;
}

.Calendar__monthArrowWrapper {
  @apply absolute top-1/2 -translate-y-1/2 rounded-full p-2;
  background-color: #FF9538 !important;
  color: #fff;
}

.Calendar__monthArrowWrapper.-right{
  @apply -left-4;
}

.Calendar__monthArrowWrapper.-left{
  @apply -right-4;
}

.Calendar__monthArrow {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" /></svg>')
}

.Calendar__monthYear {
  @apply mb-2 w-full flex-row-reverse justify-end space-x-4 space-x-reverse text-xl font-normal text-[#33475B];
}

.Calendar__monthText {
  @apply ml-0 mr-1;
}

.Calendar__monthYear:not(.-shown) > *,
.Calendar__monthYear > .-hidden {
  @apply hidden;
}

.Calendar__monthText.-activeBackground,
.Calendar__yearText.-activeBackground {
  @apply translate-x-0 scale-100;
}

.Calendar__monthText,
.Calendar__yearText {
  @apply relative after:ml-1.5 after:-rotate-[135deg] after:pr-2 after:text-sm after:text-cyan-400 after:content-["┌"];
}

.Calendar__monthText:hover,
.Calendar__yearText:hover {
  @apply translate-x-0 scale-100 bg-transparent;
}

.Calendar__sectionWrapper {
  @apply mb-4 border-b border-cyan-400;
}

.Calendar__weekDays {
  @apply text-[#32A6AE];
}

.Calendar__day.-selected,
.Calendar__day.-selectedStart,
.Calendar__day.-selectedEnd {
  background: linear-gradient(
    91.89deg,
    #32a6ae -6.8%,
    #00bba7 100.34%
  ) !important;
  @apply relative text-white;
}

.Calendar__day.-selectedStart,
.Calendar__day.-selectedEnd,
.Calendar__day.-ltr.-selectedStart,
.Calendar__day.-ltr.-selectedEnd {
  @apply rounded-full border-0 p-0;
}

.Calendar__day.-selectedStart::after {
  @apply rounded-l-full;
}

.Calendar__day.-selectedEnd::after {
  @apply rounded-r-full;
}

.Calendar__day.-selectedStart::after,
.Calendar__day.-selectedEnd::after {
  @apply absolute -z-10 h-full w-full bg-[#C0EAED] content-[''];
}

.Calendar__day.-selectedStart::after {
  background: transparent;
}

/* .Calendar__day.-selectedStart:has( + .-selectedBetween){ */

.multi-selected .Calendar__day.-selectedStart::after {
  @apply bg-[#C0EAED];
}

/* Tabs */

.ink-tabs-dropdown {
  position: absolute;
  background: #fefefe;
  border: 1px solid black;
  max-height: 200px;
  overflow: auto;
}

.ink-tabs-dropdown-hidden {
  display: none;
}

.ink-tabs-dropdown-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ink-tabs-dropdown-menu-item {
  padding: 4px 8px;
}

.ink-tabs-dropdown-menu-item-selected {
  background: red;
}

.ink-tabs-dropdown-menu-item-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.ink-tabs-content {
  @apply mt-4;
  position: relative;
  width: 100%;
}

.ink-tabs-content-holder {
  flex: auto;
}

.ink-tabs-tabpane-hidden {
  display: none;
}

.ink-tabs {
  display: flex;
  @apply border-0;
}

.ink-tabs-top,
.ink-tabs-bottom {
  flex-direction: column;
}

.ink-tabs-top .ink-tabs-ink-bar,
.ink-tabs-bottom .ink-tabs-ink-bar {
  /* @apply h-2 !w-[46px] rounded-t-md; */
  @apply h-2 rounded-t-md;
  /* height: 3px; */
}

.ink-tabs-top .ink-tabs-ink-bar {
  bottom: 0;
}

.ink-tabs-bottom .ink-tabs-nav {
  order: 1;
}

.ink-tabs-bottom .ink-tabs-content {
  order: 0;
}

.ink-tabs-bottom .ink-tabs-ink-bar {
  top: 0;
}

.ink-tabs-left.ink-tabs-editable .ink-tabs-tab,
.ink-tabs-right.ink-tabs-editable .ink-tabs-tab {
  padding-right: 32px;
}

.ink-tabs-left .ink-tabs-nav-wrap,
.ink-tabs-right .ink-tabs-nav-wrap {
  flex-direction: column;
}

.ink-tabs-left .ink-tabs-ink-bar,
.ink-tabs-right .ink-tabs-ink-bar {
  width: 3px;
}

.ink-tabs-left .ink-tabs-nav,
.ink-tabs-right .ink-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}

.ink-tabs-left .ink-tabs-nav-list,
.ink-tabs-right .ink-tabs-nav-list,
.ink-tabs-left .ink-tabs-nav-operations,
.ink-tabs-right .ink-tabs-nav-operations {
  flex: 1 0 auto;
  flex-direction: column;
}

.ink-tabs-left .ink-tabs-ink-bar {
  right: 0;
}

.ink-tabs-right .ink-tabs-nav {
  order: 1;
}

.ink-tabs-right .ink-tabs-content {
  order: 0;
}

.ink-tabs-right .ink-tabs-ink-bar {
  left: 0;
}

.ink-tabs-rtl {
  direction: rtl;
}

.ink-tabs-dropdown-rtl {
  direction: rtl;
}

.ink-tabs {
  border: 1px solid gray;
  font-size: 14px;
  overflow: hidden;
}

.ink-tabs-nav {
  display: flex;
  flex: none;
  position: relative;
}

.ink-tabs-nav-measure,
.ink-tabs-nav-wrap {
  transform: translate(0);
  position: relative;
  display: inline-block;
  flex: auto;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  @apply flex-row-reverse border-b border-cyan-400;
}

.ink-tabs-nav-measure-ping-left::before,
.ink-tabs-nav-wrap-ping-left::before,
.ink-tabs-nav-measure-ping-right::after,
.ink-tabs-nav-wrap-ping-right::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
}

.ink-tabs-nav-measure-ping-left::before,
.ink-tabs-nav-wrap-ping-left::before {
  left: 0;
  border-left: 1px solid red;
}

.ink-tabs-nav-measure-ping-right::after,
.ink-tabs-nav-wrap-ping-right::after {
  right: 0;
  border-right: 1px solid red;
}

.ink-tabs-nav-measure-ping-top::before,
.ink-tabs-nav-wrap-ping-top::before,
.ink-tabs-nav-measure-ping-bottom::after,
.ink-tabs-nav-wrap-ping-bottom::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
}

.ink-tabs-nav-measure-ping-top::before,
.ink-tabs-nav-wrap-ping-top::before {
  top: 0;
  border-top: 1px solid red;
}

.ink-tabs-nav-measure-ping-bottom::after,
.ink-tabs-nav-wrap-ping-bottom::after {
  bottom: 0;
  border-top: 1px solid red;
}

.ink-tabs-nav-list {
  @apply flex-row-reverse space-x-reverse;
  display: flex;
  position: relative;
  transition: transform 0.3s;
}

.ink-tabs-nav-operations {
  display: flex;
}

.ink-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}

.ink-tabs-nav-more {
  border: 1px solid blue;
  background: rgba(255, 0, 0, 0.1);
}

.ink-tabs-nav-add {
  border: 1px solid green;
  background: rgba(0, 255, 0, 0.1);
}

.ink-tabs-tab {
  border: 0;
  font-size: 20px;
  background: rgba(255, 255, 255, 0.5);
  margin: 0;
  display: flex;
  outline: none;
  cursor: pointer;
  position: relative;
  font-weight: lighter;
  align-items: center;
}

.ink-tabs-tab-btn,
.ink-tabs-tab-remove {
  border: 0;
  background: transparent;
}

.ink-tabs-tab-btn {
  @apply p-4 pb-6 pt-0 text-base font-light;
  font-weight: inherit;
  line-height: 32px;
}

.ink-tabs-tab-remove:hover {
  color: red;
}

.ink-tabs-tab-active {
  @apply font-light text-cyan-400;
  font-weight: bolder;
}

.ink-tabs-ink-bar {
  position: absolute;
  background-color: #ff9538;
  pointer-events: none;
}

.ink-tabs-ink-bar-animated {
  transition: all 0.3s;
}

.ink-tabs-extra-content {
  flex: none;
}

.ink-tabs-v2 .ink-tabs-nav-list {
  @apply gap-8 pr-4;
}

.ink-tabs-v2 .ink-tabs-tab-btn {
  @apply mx-0 px-0 pb-5;
}

.ink-tabs-v2 .ink-tabs-tab {
  @apply font-normal text-[#70CFD6];
}

.ink-tabs-v2 .ink-tabs-tab-active {
  @apply font-normal text-[#33475B];
}

.phil-tabs-dropdown {
  position: absolute;
  background: #fefefe;
  border: 1px solid black;
  max-height: 200px;
  overflow: auto;
}

.phil-tabs-dropdown-hidden {
  display: none;
}

.phil-tabs-dropdown-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.phil-tabs-dropdown-menu-item {
  padding: 4px 8px;
}

.phil-tabs-dropdown-menu-item-selected {
  background: red;
}

.phil-tabs-dropdown-menu-item-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.phil-tabs-content {
  @apply mt-4;
  position: relative;
  width: 100%;
}

.phil-tabs-content-holder {
  flex: auto;
}

.phil-tabs-tabpane-hidden {
  display: none;
}

.phil-tabs {
  display: flex;
  @apply border-0;
}

.phil-tabs-top,
.phil-tabs-bottom {
  flex-direction: column;
}

.phil-tabs-top .phil-tabs-ink-bar,
.phil-tabs-bottom .phil-tabs-ink-bar {
  /* @apply h-2 !w-[46px] rounded-t-md; */
  @apply h-2 rounded-t-md;
  /* height: 3px; */
}

.phil-tabs-top .phil-tabs-ink-bar {
  bottom: 0;
}

.phil-tabs-bottom .phil-tabs-nav {
  order: 1;
}

.phil-tabs-bottom .phil-tabs-content {
  order: 0;
}

.phil-tabs-bottom .phil-tabs-ink-bar {
  top: 0;
}

.phil-tabs-left.phil-tabs-editable .phil-tabs-tab,
.phil-tabs-right.phil-tabs-editable .phil-tabs-tab {
  padding-right: 32px;
}

.phil-tabs-left .phil-tabs-nav-wrap,
.phil-tabs-right .phil-tabs-nav-wrap {
  flex-direction: column;
}

.phil-tabs-left .phil-tabs-ink-bar,
.phil-tabs-right .phil-tabs-ink-bar {
  width: 3px;
}

.phil-tabs-left .phil-tabs-nav,
.phil-tabs-right .phil-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}

.phil-tabs-left .phil-tabs-nav-list,
.phil-tabs-right .phil-tabs-nav-list,
.phil-tabs-left .phil-tabs-nav-operations,
.phil-tabs-right .phil-tabs-nav-operations {
  flex: 1 0 auto;
  flex-direction: column;
}

.phil-tabs-left .phil-tabs-ink-bar {
  right: 0;
}

.phil-tabs-right .phil-tabs-nav {
  order: 1;
}

.phil-tabs-right .phil-tabs-content {
  order: 0;
}

.phil-tabs-right .phil-tabs-ink-bar {
  left: 0;
}

.phil-tabs-rtl {
  direction: rtl;
}

.phil-tabs-dropdown-rtl {
  direction: rtl;
}

.phil-tabs {
  border: 1px solid gray;
  font-size: 14px;
  overflow: hidden;
}

.phil-tabs-nav {
  display: flex;
  flex: none;
  position: relative;
}

.phil-tabs-nav-measure,
.phil-tabs-nav-wrap {
  transform: translate(0);
  position: relative;
  display: inline-block;
  flex: auto;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  @apply justify-center rounded-full border border-cyan-400;
}

.phil-tabs-nav-measure-ping-left::before,
.phil-tabs-nav-wrap-ping-left::before,
.phil-tabs-nav-measure-ping-right::after,
.phil-tabs-nav-wrap-ping-right::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
}

.phil-tabs-nav-measure-ping-left::before,
.phil-tabs-nav-wrap-ping-left::before {
  left: 0;
  border-left: 1px solid red;
}

.phil-tabs-nav-measure-ping-right::after,
.phil-tabs-nav-wrap-ping-right::after {
  right: 0;
  border-right: 1px solid red;
}

.phil-tabs-nav-measure-ping-top::before,
.phil-tabs-nav-wrap-ping-top::before,
.phil-tabs-nav-measure-ping-bottom::after,
.phil-tabs-nav-wrap-ping-bottom::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
}

.phil-tabs-nav-measure-ping-top::before,
.phil-tabs-nav-wrap-ping-top::before {
  top: 0;
  border-top: 1px solid red;
}

.phil-tabs-nav-measure-ping-bottom::after,
.phil-tabs-nav-wrap-ping-bottom::after {
  bottom: 0;
  border-top: 1px solid red;
}

.phil-tabs-nav-list {
  @apply w-full items-center justify-center p-1;
  display: flex;
  position: relative;
  transition: transform 0.3s;
}

.phil-tabs-nav-operations {
  display: flex;
}

.phil-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}

.phil-tabs-nav-more {
  border: 1px solid blue;
  background: rgba(255, 0, 0, 0.1);
}

.phil-tabs-nav-add {
  border: 1px solid green;
  background: rgba(0, 255, 0, 0.1);
}

.phil-tabs-tab {
  border: 0;
  /* font-size: 20px; */
  background: rgba(255, 255, 255, 0.5);
  margin: 0;
  display: flex;
  outline: none;
  cursor: pointer;
  position: relative;
  font-weight: lighter;
  align-items: center;
  @apply flex-shrink-0 flex-grow items-center justify-center rounded-full p-3 font-semibold text-[rgba(123,147,175,0.7)];
}

.phil-tabs-tab-btn,
.phil-tabs-tab-remove {
  border: 0;
  background: transparent;
}

.phil-tabs-tab-btn {
  @apply text-base font-semibold;
  font-weight: inherit;
  line-height: 32px;
}

.phil-tabs-tab-remove:hover {
  color: red;
}

.phil-tabs-tab-active {
  @apply bg-[#32A6AE] font-semibold text-white;
  font-weight: bolder;
}

.phil-tabs-ink-bar {
  @apply hidden;
  position: absolute;
  background-color: #ff9538;
  pointer-events: none;
}

.phil-tabs-ink-bar-animated {
  transition: all 0.3s;
}

.phil-tabs-extra-content {
  flex: none;
}

/* Tooltip */

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px !important;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
  left: 4px !important;
}

/* Tooltip */

.z-tooltip.z-tooltip-zoom-appear,
.z-tooltip.z-tooltip-zoom-enter {
  opacity: 0;
}

.z-tooltip.z-tooltip-zoom-enter,
.z-tooltip.z-tooltip-zoom-leave {
  display: block;
}

.z-tooltip-zoom-enter,
.z-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}

.z-tooltip-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}

.z-tooltip-zoom-enter.z-tooltip-zoom-enter-active,
.z-tooltip-zoom-appear.z-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}

.z-tooltip-zoom-leave.z-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}

@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }

  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}

@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}

.z-tooltip {
  @apply opacity-100 transition-all;
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  /* opacity: 0.9; */
}

.z-tooltip-hidden {
  @apply hidden transition-all;
  display: none;
}

.z-tooltip-placement-top,
.z-tooltip-placement-topLeft,
.z-tooltip-placement-topRight {
  padding: 5px 0 9px 0;
}

.z-tooltip-placement-right,
.z-tooltip-placement-rightTop,
.z-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}

.z-tooltip-placement-bottom,
.z-tooltip-placement-bottomLeft,
.z-tooltip-placement-bottomRight {
  padding: 9px 0 5px 0;
}

.z-tooltip-placement-left,
.z-tooltip-placement-leftTop,
.z-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}

.z-tooltip-inner {
  @apply mt-0 flex max-w-xs justify-start rounded-md bg-white px-4 py-4 text-[10px] text-[#7B93AF] shadow;
  text-align: left;
  text-decoration: none;
  min-height: 34px;
}

.z-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.z-tooltip-placement-top .z-tooltip-arrow,
.z-tooltip-placement-topLeft .z-tooltip-arrow,
.z-tooltip-placement-topRight .z-tooltip-arrow {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #373737;
}

.z-tooltip-placement-top .z-tooltip-arrow {
  left: 50%;
}

.z-tooltip-placement-topLeft .z-tooltip-arrow {
  left: 15%;
}

.z-tooltip-placement-topRight .z-tooltip-arrow {
  right: 15%;
}

.z-tooltip-placement-right .z-tooltip-arrow,
.z-tooltip-placement-rightTop .z-tooltip-arrow,
.z-tooltip-placement-rightBottom .z-tooltip-arrow {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #373737;
}

.z-tooltip-placement-right .z-tooltip-arrow {
  top: 50%;
}

.z-tooltip-placement-rightTop .z-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}

.z-tooltip-placement-rightBottom .z-tooltip-arrow {
  bottom: 15%;
}

.z-tooltip-placement-left .z-tooltip-arrow,
.z-tooltip-placement-leftTop .z-tooltip-arrow,
.z-tooltip-placement-leftBottom .z-tooltip-arrow {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #373737;
}

.z-tooltip-placement-left .z-tooltip-arrow {
  top: 50%;
}

.z-tooltip-placement-leftTop .z-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}

.z-tooltip-placement-leftBottom .z-tooltip-arrow {
  bottom: 15%;
}

.z-tooltip-placement-bottom .z-tooltip-arrow,
.z-tooltip-placement-bottomLeft .z-tooltip-arrow,
.z-tooltip-placement-bottomRight .z-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #373737;
}

.z-tooltip-placement-bottom .z-tooltip-arrow {
  left: 50%;
}

.z-tooltip-placement-bottomLeft .z-tooltip-arrow {
  left: 15%;
}

.z-tooltip-placement-bottomRight .z-tooltip-arrow {
  right: 15%;
}

/* .z-tooltip:not(.z-tooltip-hidden) {
  @apply inline-block transition-all opacity-100;
} */

.z-tooltip-arrow {
  @apply top-2 h-0 w-0 border-x-transparent border-l-white border-opacity-100 shadow-inner;
}

.filter-tooltip.filter-tooltip-zoom-appear,
.filter-tooltip.filter-tooltip-zoom-enter {
  opacity: 0;
}
.filter-tooltip.filter-tooltip-zoom-enter,
.filter-tooltip.filter-tooltip-zoom-leave {
  display: block;
}
.filter-tooltip-zoom-enter,
.filter-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}
.filter-tooltip-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}
.filter-tooltip-zoom-enter.filter-tooltip-zoom-enter-active,
.filter-tooltip-zoom-appear.filter-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}
.filter-tooltip-zoom-leave.filter-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}
.filter-tooltip {
  @apply max-w-xs;
  position: absolute;
  z-index: 10;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 1;
}
.filter-tooltip-hidden {
  display: none;
}
.filter-tooltip-placement-top,
.filter-tooltip-placement-topLeft,
.filter-tooltip-placement-topRight {
  padding: 5px 0 9px 0;
}
.filter-tooltip-placement-right,
.filter-tooltip-placement-rightTop,
.filter-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}
.filter-tooltip-placement-bottom,
.filter-tooltip-placement-bottomLeft,
.filter-tooltip-placement-bottomRight {
  padding: 9px 0 5px 0;
}
.filter-tooltip-placement-left,
.filter-tooltip-placement-leftTop,
.filter-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}
.filter-tooltip-inner {
  padding: 12px 27px 24px;
  color: #474e55;
  text-align: left;
  text-decoration: none;
  border-radius: 20px;
  background: #fdf9fb;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.23);
  min-width: 180px;
  min-height: 60px;
}

/* =============================================================== */

/* Define the stop colors */

#gradient-red stop[offset="0"] {
  stop-color: #f2547d;
}

#gradient-red stop[offset="1"] {
  stop-color: #ff7959;
}

/* tooltip */

.highcharts-tooltip > span {
  background-color: white;
  opacity: 1;
  z-index: 9999 !important;
  padding: 8px 8px 8px 0;
  border-radius: 16px;
}

/* =============================================================== */

.change-channel-icon {
  color: #32a6ae;
}

.change-channel-btn {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e6f7f8;
  padding: 0.5rem;
  cursor: pointer;
}

.change-channel-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.change-channel-btn__prev {
  border-radius: 20px 2px 2px 20px;
}

.change-channel-btn__next {
  border-radius: 2px 20px 20px 2px;
}

/* =================================================================== */

.scrollbar-thin::-webkit-scrollbar {
  width: 6px;
  @apply rounded-full;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background: #70cfd6;
  @apply rounded-full;
  /* -webkit-border-radius: 1ex; */
  /* -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75); */
}

.scrollbar-thin::-webkit-scrollbar-corner {
  @apply rounded-full;
}

/* ======================================================================= */

.cu-table thead th:first-child {
  @apply rounded-l-lg;
}

.cu-table thead th:last-child {
  @apply rounded-r-lg;
}

/* ======================================================================= */

/* react-select-container */
/* react-select */

/* react-select-container */
/* react-select */

.drawer-large-select__control {
  @apply block w-full rounded-t-md border bg-white px-3 py-1 leading-7 placeholder-gray-400 shadow-sm focus:border-[#CACED8] focus:outline-none focus:ring-1 focus:ring-[#CACED8] disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 disabled:shadow-none;
}

.drawer-large-select__value-container {
}

.select__indicators {
}

.drawer-large-select__menu {
  @apply w-full rounded-b-lg border border-t-0 border-[#e5e7eb] bg-[#F8EEF3] shadow-lg;
}

.drawer-large-select__menu-list {
}

.drawer-large-select__option {
  @apply cursor-pointer;
}

/* ======================================================================= */

/* react-select-container */
/* react-select */

/* react-select-container */
/* react-select */

.drawer-small-select__control {
  @apply block w-full rounded-t-md border bg-white px-3 py-1 leading-7 placeholder-gray-400 shadow-sm focus:border-[#CACED8] focus:outline-none focus:ring-1 focus:ring-[#CACED8] disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 disabled:shadow-none;
}

.drawer-small-select__value-container {
}

.select__indicators {
}

.drawer-small-select__menu {
  @apply w-full rounded-b-lg border border-t-0 border-[#e5e7eb] bg-white shadow-lg;
}

.drawer-small-select__menu-list {
}

.drawer-small-select__option {
  @apply cursor-pointer px-5 py-1;
}
