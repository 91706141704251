.box-wrapper {
  border-radius: 0.625rem;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  flex-shrink: 0;
}

.box-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.box-title {
  color: #474e55;
  width: 100%;
  font-size: 1.5rem;
  line-height: 2rem;
}

.btn {
  display: inline-flex;
  gap: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.btn-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(230 247 248 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(71 78 85 / var(--tw-text-opacity));
}

.btn-pill {
  border-radius: 9999px;
}

.badge {
  min-width: 1.5rem;
  display: inline-block;
  border-radius: 9999px;
  padding: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
}

.badge-danger {
  --tw-bg-opacity: 1;
  background-color: rgb(242 84 125 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.chart-container {
  position: relative;
}

.highcharts-button-symbol {
  display: none;
  background-color: transparent;
}



