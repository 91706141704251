.w-full {
  width: 100%;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.page-title {
  display: flex;
  align-items: center;
}

.page-title--left {
  display: flex;
  align-items: center;
}

.page-title--left > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.page-title--main-text {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(51 71 91 / var(--tw-text-opacity));
}

.page-title--extra-text {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgb(0 145 174 / var(--tw-text-opacity));
}

.jibe-summery {
  position: relative;
  display: flex;
}

.jibe-summery > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4rem * var(--tw-space-x-reverse));
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
}

.jibe-summery {
  overflow: hidden;
  border-radius: 20px;
  padding: 4rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  --tw-shadow: 0px 1.45255px 8px rgba(0,0,0,0.13);
  --tw-shadow-colored: 0px 1.45255px 8px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (min-width: 1024px) {
  .jibe-summery {
    align-items: center;
  }
}

.jibe-summery::before {
  position: absolute;
  top: -65px;
  right: -35px;
  z-index: -10;
  height: 172px;
  width: 172px;
  --tw-content: '';
  content: var(--tw-content);
}

.jibe-summery::after {
  position: absolute;
  bottom: -80px;
  left: -30px;
  z-index: -10;
  height: 116px;
  width: 116px;
  --tw-content: '';
  content: var(--tw-content);
}

.jibe-summery::before {
  background-image: url(/src/assets/images/vectors/jibe-summary-light-teal-bubble.svg);
}

.jibe-summery::after {
  background-image: url(/src/assets/images/vectors/jibe-summary-gray-bubble.svg);
}

.jibe-summery-title {
  margin-top: 2rem;
  max-width: 101px;
  white-space: break-spaces;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgb(51 71 91 / var(--tw-text-opacity));
}

@media (min-width: 1024px) {
  .jibe-summery-title {
    margin-top: 0px;
  }
}

.jibe-summery-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
}

.jibe-summery-content > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

@media (min-width: 1024px) {
  .jibe-summery-content {
    flex-direction: row;
    align-items: center;
  }

  .jibe-summery-content > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
}

.jibe-summery-agent-detail-numeric {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.jibe-summery-agent-detail-changes {
  display: flex;
  flex-direction: column;
}

.jibe-summery-agent-detail-changes-percent {
  margin-bottom: 0.5rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.jibe-summery-agent-detail-changes-analysis {
  display: flex;
  align-items: center;
}

.jibe-summery-agent-detail-changes-analysis > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.jibe-summery-agent-detail-changes-analysis {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 300;
}

.jibe-summery-agent-detail-changes-analysis-count {
  --tw-text-opacity: 1;
  color: rgb(123 147 175 / var(--tw-text-opacity));
}

.jibe-summery-agent-detail-changes-analysis-title {
  --tw-text-opacity: 1;
  color: rgb(66 91 118 / var(--tw-text-opacity));
}

.jibe-summery-agent-detail-changes-analysis-icon {
  height: 0.75rem;
  width: 0.75rem;
}

.jibe-summery-agent-detail-total {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jibe-summery-agent-detail-total-count {
  font-size: 1.5rem;
  line-height: 2rem;
  --tw-text-opacity: 1;
  color: rgb(73 194 203 / var(--tw-text-opacity));
}

.jibe-summery-agent-detail-total-title {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 300;
}

.jibe-summery-agent-detail-progress {
  margin-top: 0.5rem;
}

.jibe-summery-agent {
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 1024px) {
  .jibe-summery-agent {
    width: auto;
  }
}

.jibe-summery-sessions-analysis-range {
  border-radius: 3.49666px;
  --tw-bg-opacity: 1;
  background-color: rgb(242 246 249 / var(--tw-bg-opacity));
  padding: 0.125rem;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  font-size: 0.65rem;
}

.jibe-summery-sessions-analysis-icon {
  height: 0.75rem;
  width: 0.75rem;
}

.jibe-summery-sessions-analysis {
  display: flex;
  align-items: center;
  justify-content: center;
}

.jibe-summery-sessions-analysis > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.jibe-summery-sessions-analysis {
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(123 147 175 / var(--tw-text-opacity));
}

.jibe-summery-sessions-title {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 300;
  --tw-text-opacity: 1;
  color: rgb(66 91 118 / var(--tw-text-opacity));
}

.jibe-summery-sessions-count {
  margin-bottom: 0.75rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  --tw-text-opacity: 1;
  color: rgb(51 71 91 / var(--tw-text-opacity));
}

.jibe-summery-sessions {
  display: flex;
  flex-direction: column;
  align-items: center;
}
