.comp-progress-trail {
  position: relative;
  height: 1.75rem;
  width: 100%;
  overflow: hidden;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(230 247 248 / var(--tw-bg-opacity))
}

.comp-progress-stroke {
  position: absolute;
  left: 0px;
  z-index: 0;
  display: flex;
  height: 1.75rem;
  border-radius: 9999px;
  background-size: auto;
  background-position: center;
  background-repeat: repeat
}
